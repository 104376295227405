import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

function NonLiniarSlider({ title, opt, handleOnChange, value }) {
  //  const handleOnChange = (event, newValue) => {
  //   console.log('debug1-> handleOnChange[NonLiniarSlider]: ', newValue);
  //  }

  return (
    <Box sx={{ width: 250 }}>
      <Typography gutterBottom>{title}</Typography>
      <Slider
        value={value}
        aria-label={title}
        defaultValue={opt.defaultValue}
        step={opt.step}
        marks={opt.marks}
        min={opt.min}
        max={opt.max}
        track={false}
        onChange={handleOnChange}
        valueLabelDisplay={opt.showLabelValueOnTop ? "on" : "off"}
        componentsProps={{
          markLabel: {
            style: { color: "white" },
          },
        }}
      />
    </Box>
  );
}

export default NonLiniarSlider;
