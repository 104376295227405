import React, { useEffect, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import { FilterContext } from "../FilterContext/FilterContext.js";
import NonLiniarSlider from "../Sliders/nonLiniarSlider";
import LiniarSliderSlider from "../Sliders/liniarSlider";
import filtersConfig from "./filters.enum.js";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { MODE } from './../constants/constants.js';

import "./filters.css";

const Filters = ({ applyFilters, removeFilters }) => {
  const [filters, setFilters] = useContext(FilterContext);

  const handleOnChangeEV = (event, newValue) => {
    console.log("debug1-> handleOnChangeEV: ", newValue);
    setFilters({
      ...filters,
      filtersMap: {
        ...filters.filtersMap,
        ev: newValue,
      },
    });
  };

  const handleOnChangeISO = (event, newValue) => {
    console.log("debug1-> handleOnChangeEV: ", newValue);
    setFilters({
      ...filters,
      filtersMap: {
        ...filters.filtersMap,
        iso: newValue,
      },
    });
  };

  const handleOnChangeColorProfile = (event, newValue) => {
    console.log("debug1-> handleOnChangeColor: ", newValue);
    setFilters({
      ...filters,
      filtersMap: {
        ...filters.filtersMap,
        colorProfile: newValue,
      },
    });
  };

  const handleChangeShutter = (event, newValue) => {
    console.log("debug1-> handleOnChangeEV: ", newValue);
    setFilters({
      ...filters,
      filtersMap: {
        ...filters.filtersMap,
        shutter: newValue,
      },
    });
  };

  const handleChangeWB = (event, newValue) => {
    console.log("debug1-> handleOnChangeEV: ", newValue);
    setFilters({
      ...filters,
      filtersMap: {
        ...filters.filtersMap,
        wb: newValue,
      },
    });
  };

  const handleOnChangeHyperSmooth = (event, newValue) => {
    console.log("debug1-> handleOnChangeHypersmooth: ", newValue);
    setFilters({
      ...filters,
      hyperSmooth: newValue,
    });
  };

  const handleChangeMode = (event, newValue) => {
    console.log("debug1-> handleOnChangeMode: ", newValue);
    setFilters({
      ...filters,
      mode: newValue ? "video" : "photo",
    });
  };
  useEffect(() => {
    console.log("debug1-> filters: ", filters);
  }, [filters]);

  return (
    <>
      <h1>ProTune</h1>
      <div className="filters">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Photo</Typography>
          <Switch
            checked={filters.mode === "video"}
            onChange={handleChangeMode}
          />
          <Typography>Video</Typography>
        </Stack>
        <br></br>

        <NonLiniarSlider
          title="EV"
          value={filters.filtersMap.ev}
          opt={filtersConfig.EvSliderOptions}
          handleOnChange={handleOnChangeEV}
        />
        <br></br>
        <br></br>
        <NonLiniarSlider
          title="ISO"
          value={filters.filtersMap.iso}
          opt={filtersConfig.IsoSliderOptions}
          handleOnChange={handleOnChangeISO}
        />
        <br></br>
        <br></br>
        <LiniarSliderSlider
          title="Shutter Speed"
          opt={filtersConfig.ShutterSpeedSliderOptions}
          value={filters.filtersMap.shutter}
          handleChange={handleChangeShutter}
        />
        <br></br>
        <br></br>
        <NonLiniarSlider
          title="Color Profile"
          value={filters.filtersMap.colorProfile}
          opt={filtersConfig.ColorProfileSliderOptions}
          handleOnChange={handleOnChangeColorProfile}
        />
        <br></br>
        <br></br>
        <LiniarSliderSlider
          title="White Balance"
          opt={filtersConfig.WhiteBalanceSliderOptions}
          value={filters.filtersMap.wb}
          handleChange={handleChangeWB}
        />
        <br></br>
        <br></br>
        <br></br>
        {filters.mode === "video" && (
          <NonLiniarSlider
            title="HyperSmooth"
            value={filters.hyperSmooth}
            opt={filtersConfig.HyperSmoothSliderOptions}
            handleOnChange={handleOnChangeHyperSmooth}
          />
        )}
        <br></br>
        <br></br>
        <div className="filters__buttons">
          {filters.mode === MODE.VIDEO && (
            <Button
              style={{ marginRight: "20px" }}
              onClick={applyFilters}
              variant="contained"
              color="primary"
              size="small"
            >
              Capture Frame
            </Button>
          )}
          <br></br>
          <Button
            onClick={removeFilters}
            variant="contained"
            color="secondary"
            size="small"
          >
            Reset
          </Button>
          <br></br>
        </div>
      </div>
    </>
  );
};

export default Filters;
