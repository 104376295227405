import { useContext } from "react";
import "./FabricJSVideoExample.css";
import { FilterContext } from "../FilterContext/FilterContext.js";

const FabricJSVideoExample = (props) => {
  const [filters, setFilters] = useContext(FilterContext);

  let hyperSmoothClasses = "";
  switch (props?.hyperSmoothValue) {
    case 1:
      hyperSmoothClasses = "shakeLittle";
      break;
    case 2:
      hyperSmoothClasses = "shakeMedium";
      break;
    default:
      hyperSmoothClasses = "";
  }
  const canvasClassNames = `video-example ${
    filters.aspectRatio === "Vertical" ? "video-example-vertical" : ""
  }`;
  return (
    <div className={canvasClassNames}>
      <canvas
        id="c"
        width="800"
        height="600"
        className={hyperSmoothClasses}
      ></canvas>
    </div>
  );
};

export default FabricJSVideoExample;
