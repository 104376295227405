import React, { useEffect, useContext, useRef } from "react";

import { FilterContext } from "../FilterContext/FilterContext.js";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import "./VideoSelectors.css";

const VideoSelectors = ({ applyFilters, removeFilters }) => {
  const [filters, setFilters] = useContext(FilterContext);

  const handleChangeActivity = (event) => {
    setFilters({
      ...filters,
      activity: event.target.value,
    });
  };
  const handlePresets = (preset) => {
    let presets = {};
    switch (preset) {
      case "landscape":
        presets = {
          ev: 0,
          shutter: 200,
          iso: 1,
          wb: 5300,
          sharpness: null,
          colorProfile: 2,
          testGrayscale: null,
        };
        break;
      case "bike":
        presets = {
          ev: 0,
          shutter: 220,
          iso: 1,
          wb: 5300,
          sharpness: null,
          colorProfile: 2,
          testGrayscale: null,
        };
        break;
      case "snow":
        presets = {
          ev: 0,
          shutter: 220,
          iso: 1,
          wb: 5800,
          sharpness: null,
          colorProfile: 3,
          testGrayscale: null,
        };
        break;
      default:
        presets = {
          ev: 0,
          shutter: 200,
          iso: 1,
          wb: 5300,
          sharpness: null,
          colorProfile: 2,
          testGrayscale: null,
        };
        break;
    }
    return presets;
  };

  const handleChangePreset = (event) => {
    setFilters({
      ...filters,
      filtersMap: handlePresets(event.target.value),
      preset: event.target.value,
    });
  };

  useEffect(() => {
    console.log("debug1-> filters: ", filters);
  }, [filters]);

  return (
    <>
      <div className="activity">
        <FormControl
          sx={{
            m: 1,
            minWidth: 200,
            width: 200,
          }}
          size="small"
        >
          <Select
            value={filters.activity}
            label="Activity Selector"
            onChange={handleChangeActivity}
            sx={{
              color: "rgb(25, 118, 210)",
              border: "2px solid rgb(25, 118, 210)",
            }}
          >
            <MenuItem value={"static"}>Static</MenuItem>
            <MenuItem value={"bike"}>Bike</MenuItem>
            <MenuItem value={"snow"}>Snow</MenuItem>
            <MenuItem value={"night"}>Night Shot</MenuItem>
            <MenuItem value={"sunrise"}>Sunrise</MenuItem>
          </Select>
          <FormHelperText
            sx={{ color: "rgb(25, 118, 210)", borderColor: "white" }}
          >
            Select Activity Video
          </FormHelperText>
        </FormControl>
      </div>
      <div className="presets">
        <FormControl
          sx={{
            m: 1,
            minWidth: 200,
            width: 200,
          }}
          size="small"
        >
          <Select
            value={filters.preset}
            label="Preset Selector"
            onChange={handleChangePreset}
            sx={{
              color: "rgb(25, 118, 210)",
              border: "2px solid rgb(25, 118, 210)",
            }}
          >
            <MenuItem value={"landscape"}>Best for Landscapes</MenuItem>
            <MenuItem value={"bike"}>Best for Biking</MenuItem>
            <MenuItem value={"snow"}>Best for SnowSport</MenuItem>
          </Select>
          <FormHelperText
            sx={{ color: "rgb(25, 118, 210)", borderColor: "white" }}
          >
            Select Presets for Activity
          </FormHelperText>
        </FormControl>
      </div>
    </>
  );
};

export default VideoSelectors;
