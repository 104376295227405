import { fabric } from "fabric";

const composeFilters = (filters) => {
  console.log("debug1-> video play clicked!");

  const evFilter = new fabric.Image.filters.Brightness({
    brightness: filters.filtersMap.ev / 8,
  });

  const isoFilter = [
    new fabric.Image.filters.Noise({
      noise: filters.filtersMap.iso * 10,
    }),
    new fabric.Image.filters.Brightness({
      brightness: filters.filtersMap.iso / 30,
    }),
  ];
  let colourProfileFilter = [];
  switch (filters.filtersMap.colorProfile) {
    case 1:
      colourProfileFilter.push(
        new fabric.Image.filters.Contrast({
          contrast: -0.1,
        }),
        new fabric.Image.filters.Saturation({
          saturation: -0.2,
        }),
        new fabric.Image.filters.Vibrance({
          vibrance: -0.3,
        })
      );
      break;
    case 2:
      break;
    case 3:
      colourProfileFilter.push(
        new fabric.Image.filters.Contrast({
          contrast: 0.1,
        }),
        new fabric.Image.filters.Saturation({
          saturation: 0.2,
        }),
        new fabric.Image.filters.Vibrance({
          vibrance: 0.3,
        })
      );
      break;
    default:
      colourProfileFilter.push(
        new fabric.Image.filters.Contrast({
          contrast: 0.1,
        }),
        new fabric.Image.filters.Saturation({
          saturation: 0.2,
        }),
        new fabric.Image.filters.Vibrance({
          vibrance: 0.3,
        })
      );
      break;
  }

  const shutterFilter = new fabric.Image.filters.Brightness({
    brightness: -0.5 + 100 / filters.filtersMap.shutter,
  });

  const yellowBlendFilter = new fabric.Image.filters.BlendColor({
    color: "yellow",
    mode: "add",
    alpha: filters.filtersMap.wb / 5300 - 1.05,
  });

  const redBlendFilter = new fabric.Image.filters.BlendColor({
    color: "red",
    mode: "add",
    alpha: filters.filtersMap.wb / 5300 - 1.05,
  });

  const wbFilter =
    filters.filtersMap.wb / 5300 <= 1
      ? [
          new fabric.Image.filters.BlendColor({
            color: "blue",
            mode: "add",
            alpha: 1 - filters.filtersMap.wb / 5300,
          }),
        ]
      : [yellowBlendFilter, redBlendFilter];

  return [
    evFilter,
    ...isoFilter,
    ...colourProfileFilter,
    shutterFilter,
    ...wbFilter,
  ];
};

export default composeFilters;
