import { useEffect, useContext, useRef, useState } from "react";
import { fabric } from "fabric";
import Filters from "../Filters/filters.js";
import "./SimContainer.css";
import { FilterContext } from "../FilterContext/FilterContext.js";
import videoSource from "./test-video3.mp4";
import videoSourceHyperview from "./hyperview.mp4";
import videoSourceHyperviewSquare from "./hyperview2.mp4";
import staticVideoLinear from "./static_linear.mp4";
import staticVideoHyperview from "./static_hyperview.mp4";
import staticVideoSuperview from "./static_superview.mp4";
import staticVideoWide from "./static_wide.mp4";
import nightVideo from "./night.mp4";
import bikeVideo from "./bike.mp4";
import snowVideo from "./snow.mp4";
import hyperViewVideo from "./hyperview.mp4";
import cameraImage from "./camera.png";
import FabricJSVideoExample from "../FabricJSVideoExample/FabricJSVideoExample.js";
import Screens from "../Screens/screens.js";
import composeFilters from "./filters.utils.js";
import VideoSelectors from "../VideoSelectors/VideoSelectors.js";
import Photos from "../Photos/photos.js";
import { HYPER_SMOOTH, MODE } from "../constants/constants.js";

const SimContainer = () => {
  const [filters, setFilters] = useContext(FilterContext);
  const [videoEl, setVideoEl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef();

  const canvas = new fabric.Canvas("c");
  canvas.setWidth(800);
  canvas.setHeight(600);

  //   Set video based on selected lens
  let selectedVideoSource = videoSource;
  switch (filters.lens) {
    case "WIDE":
      selectedVideoSource = staticVideoWide;
      break;
    case "LINEAR":
      selectedVideoSource = staticVideoLinear;
      break;
    case "HYPERVIEW":
      selectedVideoSource = staticVideoHyperview;
      break;
    case "SUPERRVIEW":
      selectedVideoSource = staticVideoSuperview;
      break;
    default:
      selectedVideoSource = staticVideoWide;
  }

  switch (filters.activity) {
    case "static":
      if (filters.lens === "WIDE") {
        selectedVideoSource = staticVideoWide;
      }
      break;
    case "snow":
      if (filters.lens === "WIDE") {
        selectedVideoSource = snowVideo;
      }
      break;
    case "bike":
      if (filters.lens === "WIDE") {
        selectedVideoSource = bikeVideo;
      }
      break;
    case "night":
      if (filters.lens === "WIDE") {
        selectedVideoSource = nightVideo;
      }
      break;
    case "sunrise":
      if (filters.lens === "WIDE") {
        selectedVideoSource = hyperViewVideo;
      }
      break;
    default:
      break;
  }

  useEffect(() => {
    const videoElement = new fabric.Image(videoRef.current, {
      left: 0,
      top: 100,
    });

    setVideoEl(videoElement);
  }, []);

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleRemoveFilters = () => {
    setIsPlaying(true);
    console.log("debug1-> video stop clicked!");
    setFilters({
      ...filters,
      appliedFilters: [],
      filtersMap: {
        ev: 0,
        shutter: 200,
        iso: 1,
        wb: 5300,
        sharpness: null,
        colorProfile: 2,
        testGrayscale: null,
      },
      hyperSmooth: 0,
    });
  };

  const [capturedFrames, setFrameCapture] = useState([]);


  const handleCaptureVideoFrame = () => {
    setIsPlaying(true);
    const video = videoRef.current;

    if (video && canvas) {
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const frameData = canvas.toDataURL('image/png');
      setFrameCapture([...capturedFrames, { data:frameData, appliedFilters: { activity: filters.activity, lens: filters.lens, hyperSmooth: HYPER_SMOOTH[filters.hyperSmooth], filtersMap: filters.filtersMap }} ]);
    }
  };

  useEffect(() => {
    if (videoEl) {
      canvas.add(videoEl);
      videoRef.current.load();
      canvas.item(0).applyFilters();
      canvas.renderAll();

      // Add a filters to every frame
      fabric.util.requestAnimFrame(function render() {
        const image = canvas.item(0);
        const backend = fabric.filterBackend;
        if (backend && backend.evictCachesForKey) {
          backend.evictCachesForKey(image.cacheKey);
          backend.evictCachesForKey(image.cacheKey + "_filtered");
        }
        canvas.item(0).applyFilters();
        canvas.item(0).imageSmoothingEnabled = true;
        canvas.renderAll();
        fabric.util.requestAnimFrame(render);
      });
    }
  }, [filters.appliedFilters, videoEl, selectedVideoSource, capturedFrames, canvas, filters.lens]);

  useEffect(() => {
    if (videoEl) {
      videoEl.filters = [...composeFilters(filters)];
    }
  }, [filters, videoEl, selectedVideoSource, capturedFrames, canvas, isPlaying]);

  return (
    <>
      <div className="container">
      { capturedFrames.length > 0 && <Photos frames={capturedFrames} /> }
        <div className="left">
          <FabricJSVideoExample hyperSmoothValue={filters.hyperSmooth} />
          <div className={`image-container image-container--startRecording`}>
            <img className="camera-image" src={cameraImage} alt="camera" />
            <Screens isPlaying={isPlaying} handlePlayPause={handlePlayPause} />
            
          </div>
          <div className="selectors">
            <VideoSelectors />
          </div>

          <video
            id="video2"
            style={{ display: "none" }}
            className="canvas-img"
            width="800"
            height="600"
            autoPlay={true}
            loop={true}
            ref={videoRef}
            crossOrigin="anonymous"
          >
            <source src={selectedVideoSource} />
          </video>
        </div>
        <div className="right">
          <Filters
            applyFilters={handleCaptureVideoFrame}
            removeFilters={handleRemoveFilters}
          />
        </div>
      </div>
    </>
  );
};

export default SimContainer;
