export const HYPER_SMOOTH = {
  0: "Boost",
  1: "On",
  2: "Off",
 }

 export const COLOR_PROFILE = {
  1: "Flat",
  2: "GoPro",
  3: "Vibrant",
 }

export const MODE = {
 VIDEO: "video",
 PHOTO: "photo",
 TIMELAPSE: "timelapse",
}

export const LENS = {
 HYPERVIEW: {
   title: 'HyperView (12mm)',
   description: 'Our tallest field of view. Great for capturing immersive point-of-view footage',
   initial: 'H',
 },
 SUPERVIEW: {
   title: 'SuperView (16mm)',
   description: 'Tall field of view that\'s great for point-of-view shots.',
   initial: 'S',
 },
 WIDE: {
   title: 'Wide (16-34mm)',
   description: 'Wide, versatile field of view that\'s great for everyday shots and selfies.',
   initial: 'W',
 },
 LINEAR: {
   title: 'Liniar (19-39mm)',
   description: 'Wide field of view without fisheye effect. Great for landscapes and scenic shots.',
   initial: 'L',
 }
}

export const QUALITY = {
 High: {
   title: 'Highest Quality',
   description: 'Captures video in 5.3K for highest quality. Some lens and slow motion combinations may use lower resolutions. 5.3K has 91% higher resolution than 4K and allows for 16MP video stills.',
   initial: 'H',
   icon: '▣',
 },
 Standard: {
   title: 'Standard Quality',
   description: 'Captures video in 4K. Some lens and slow motion combinations may use lower resolutions. 4K is ideal for compatibility with phones that struggles to playback higher resolution video.',
   initial: 'S',
   icon: '◱',
 },
 Basic: {
   title: 'Basic Quality',
   description: 'Captures video in 1080p HD to maximize battery life.',
   initial: 'B',
   icon: '◻',
 }
}

export const FRAMING = {
 Full: {
   title: 'Full Frame',
   description: 'Captures full frame video allowing you to switch between widescreen, vertical and other framing options using the GoPro Quik App.',
   initial: 'F',
   icon: '⤬',
 },
 Vertical: {
   title: 'Vertical',
   description: 'Captures vertical video for vertical playback on your phone and social media.',
   initial: 'V',
   icon: '⭥',
 },
 Widescreen: {
   title: 'Widescreen',
   description: 'Captures widescreen video for full-screen playback.',
   initial: 'W',
   icon: '⭤',
 }
}

export const PANEL_TYPES = {
 LENS: 'lens',
 QUALITY: 'quality',
 FRAMING: 'framing',
}