import React, { createContext, useState } from "react";

// Create Context Object
export const FilterContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const FilterContextProvider = (props) => {
  const [filters, setFilters] = useState({
    appliedFilters: [],
    filtersMap: {
      ev: 0,
      shutter: 200,
      iso: 1,
      wb: 5300,
      sharpness: null,
      colorProfile: 2,
      testGrayscale: null,
    },
    mode: "video",
    lens: "WIDE",
    hyperSmooth: 0,
    activity: "static",
    light: "day",
    preset: "landscape",
  });

  return (
    <FilterContext.Provider value={[filters, setFilters]}>
      {props.children}
    </FilterContext.Provider>
  );
};
