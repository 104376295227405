import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

function LiniarSlider({ title, opt, value, handleChange }) {
  function calculateValue(value) {
    return value;
  }

  return (
    <Box sx={{ width: 250 }}>
      <Typography id="non-linear-slider" gutterBottom>
        {title}: {opt.formatValue(calculateValue(value))}
      </Typography>
      <Slider
        value={value}
        defaultValue={opt.defaultValue}
        min={opt.min}
        step={opt.step}
        max={opt.max}
        scale={calculateValue}
        getAriaValueText={opt.formatValue}
        valueLabelFormat={opt.formatValue}
        valueLabelDisplay={opt.showLabelValueOnTop ? "on" : "off"}
        onChange={handleChange}
        aria-labelledby={title}
        track={false}
        marks={opt.marks}
      />
    </Box>
  );
}

export default LiniarSlider;
