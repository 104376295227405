import React from 'react';
import dot from "../assets/dot.svg";

function Panels({ showPanel, type, handleShowPanel, selectedItem, panelData, handleItemSelection }) {
 const items = Object.keys(panelData).map((item) => item);
 
 const HEADER = {
  lens: 'Lens',
  quality: 'Video Mode',
  framing: 'Framing',
 };

 return (
  <div className={`camera-lens-screen${!showPanel ? '--hidden' : ''}`}>
   <div className="content" onClick={handleShowPanel}>
    <h5 className="header">{HEADER[type]}</h5>
    <div className="title">
     <span>{selectedItem.title}</span>
    </div>
    <div className="description">
     <span>{selectedItem.description}</span>
    </div>
   </div>
   <div className="lens">
    {items.map((item) => {
     if (panelData[item].initial === selectedItem.initial) {
      return (<div key={panelData[item].initial} className={`camera__bottom__lens camera__bottom__lens--invert ${type !== 'lens' ? 'camera__bottom--bigText' : ''}`} ><span>{type === 'lens' ? panelData[item].initial : panelData[item]?.icon}</span></div>);
     }
     return (<img key={panelData[item].initial} src={dot} alt="dot" onClick={(e) => handleItemSelection(e, item)} />);
    })}
   </div>
  </div>
 )
}

export default Panels;