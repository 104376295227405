import './photos.css';
import { COLOR_PROFILE } from '../constants/constants';

function Photos({ frames }) {

 const mapFilters = {
  ev: 'EV',
  shutter: 'Shutter',
  iso: 'ISO',
  wb: 'WB',
  sharpness: 'Sharpness',
  colorProfile: 'Color Profile',
  testGrayscale: 'Grayscale',
 };

 return (
  <div className="photo-wrapper">
   {frames.map((frame, index) => {
    return (
     <div key={index} className='item'>
       <div className="polaroid">
        <img className="photo" src={frame.data} alt="captured frame" />
        <pre class="metadata">
         <ul style={{ textAlign: 'left', listStyle: 'none'}}>
          <li>Activity: {frame.appliedFilters.activity}</li>
          <li>Lens: {frame.appliedFilters.lens}</li>
          <li>HyperSmooth: {frame.appliedFilters.hyperSmooth}</li>
         </ul>
         <ul style={{ textAlign: 'left', listStyle: 'none'}}>
          { Object.keys(frame.appliedFilters.filtersMap).map((filter, index) => {

           if(['wb', 'colorProfile', 'ev' ].includes(filter)) {
            return (
             <li key={index}>{mapFilters[filter]}: {filter === 'colorProfile' ? COLOR_PROFILE[frame.appliedFilters.filtersMap[filter]] : frame.appliedFilters.filtersMap[filter]} { filter === 'wb' ? 'K' : ''}</li>
            )
           }
           })}
         </ul>
        </pre>
       </div>
      </div>
    );
   })}
  </div>
 );
}

export default Photos;