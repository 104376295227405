import { useState, useContext } from "react";
import Panels from "../Panels/panels";
import {
  MODE,
  LENS,
  QUALITY,
  FRAMING,
  PANEL_TYPES,
} from "../constants/constants";
import battery from "./../assets/battery.svg";
import video from "./../assets/video.svg";
import photo from "./../assets/photo.svg";
import timelapse from "./../assets/timelapse.svg";
import card from "./../assets/card.svg";
import dot from "./../assets/dot.svg";
import PlayVideoIcon from './../assets/play.svg';
import PauseVideoIcon from './../assets/stop.svg';
import './screens.css';
import { FilterContext } from "../FilterContext/FilterContext.js";

function Screens({ isPlaying, handlePlayPause }) {
  const [filters, setFilters] = useContext(FilterContext);
  const [showLensPanel, setShowLensPanel] = useState(false);
  const [selectedLens, setSelectedLens] = useState(LENS.WIDE);
  const [showQualityPanel, setShowQualityPanel] = useState(false);
  const [selectedQuality, setSelectedQuality] = useState(QUALITY.Standard);
  const [showFramingPanel, setShowFramingPanel] = useState(false);
  const [selectedFraming, setSelectedFraming] = useState(FRAMING.Widescreen);

  // Handle Mode Selection
  const handleModeChange = (e, mode) => {
    setFilters({
      ...filters,
      mode: mode,
    });
  };

  // Handle Lens Panel
  const handleLensChange = (e, lensType) => {
    console.log("lensType", lensType);
    setSelectedLens(LENS[lensType]);
    setFilters({
      ...filters,
      lens: lensType,
    });
  };
  const handleShowLensPanel = () => {
    setShowLensPanel(!showLensPanel);
  };

  // Handle Quality Panel
  const handleQualityChange = (e, videoQuality) => {
    console.log("videoQuality", videoQuality);
    setSelectedQuality(QUALITY[videoQuality]);
  };
  const handleShowQualityPanel = () => {
    setShowQualityPanel(!showQualityPanel);
  };

  // Handle Framing Panel
  const handleFramingChange = (e, framing) => {
    console.log('framing', framing);
    setSelectedFraming(FRAMING[framing]);
    setFilters({
      ...filters,
      aspectRatio: framing,
    });
  };
  const handleShowFramingPanel = () => {
    setShowFramingPanel(!showFramingPanel);
  }

  return (
    <div className="camera-container">
      <Panels
        showPanel={showLensPanel}
        type={PANEL_TYPES.LENS}
        handleShowPanel={handleShowLensPanel}
        selectedItem={selectedLens}
        panelData={LENS}
        handleItemSelection={handleLensChange}
      />


      <Panels
        showPanel={showQualityPanel}
        type={PANEL_TYPES.QUALITY}
        handleShowPanel={handleShowQualityPanel}
        selectedItem={selectedQuality}
        panelData={QUALITY}
        handleItemSelection={handleQualityChange}
      />

      <Panels
        showPanel={showFramingPanel}
        type={PANEL_TYPES.FRAMING}
        handleShowPanel={handleShowFramingPanel}
        selectedItem={selectedFraming}
        panelData={FRAMING}
        handleItemSelection={handleFramingChange}
      />

      {/* {filters.mode === MODE.VIDEO && ( <div className='camera-actions' onClick={handlePlayPause}>
        {isPlaying ? <img className='stop-video-btn' src={PauseVideoIcon} alt="Pause" /> : <img className='start-video-btn' src={PlayVideoIcon} alt="Pause" />}
      </div>) } */}
     

      <div className="camera-screen">
        <div className="camera__top">
          <div className="camera__top__card">
            <img src={card} alt="card" />
            <span>5H:13</span>
          </div>
          <div className="camera__top__mode">
            <img
              onClick={(e) => handleModeChange(e, MODE.TIMELAPSE)}
              src={filters.mode === MODE.TIMELAPSE ? timelapse : dot}
              alt="timelapse"
            />
            <img
              onClick={(e) => handleModeChange(e, MODE.VIDEO)}
              src={filters.mode === MODE.VIDEO ? video : dot}
              alt="video"
            />
            <img
              onClick={(e) => handleModeChange(e, MODE.PHOTO)}
              src={filters.mode === MODE.PHOTO ? photo : dot}
              alt="photo"
            />
          </div>
          <div className="camera__top__battery">
            <span>100%</span>
            <img src={battery} alt="battery" />
          </div>
        </div>
        <div className="camera-bottom">
          {/* Lens */}
          <div className="camera__bottom__lens" onClick={handleShowLensPanel}>
            <span>{selectedLens.initial}</span>
          </div>
          {/* Quality */}
          <div className="camera__bottom__quality" onClick={handleShowQualityPanel}>
            <span>{selectedQuality.title}</span>
          </div>
          {/* Framing */}
          <div className="camera__bottom__framing" onClick={handleShowFramingPanel}>
            <div className="camera__bottom__frame">
              <span>{selectedFraming.icon}</span>
            </div>
          </div>
        </div>
      </div>
    </div>);
}

export default Screens;
