const filtersConfig = {
  EvSliderOptions: {
    defaultValue: 0,
    min: -2,
    step: 1,
    max: 2,
    showLabelValueOnTop: false,
    marks: [
      {
        label: "-2",
        value: -2,
      },
      {
        label: "-1",
        value: -1,
      },
      {
        label: "0",
        value: 0,
      },
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
    ],
  },
  IsoSliderOptions: {
    defaultValue: 1,
    min: 1,
    step: 1,
    max: 6,
    showLabelValueOnTop: false,
    marks: [
      {
        label: "100",
        value: 1,
      },
      {
        label: "200",
        value: 2,
      },
      {
        label: "400",
        value: 3,
      },
      {
        label: "800",
        value: 4,
      },
      {
        label: "1600",
        value: 5,
      },
      {
        label: "3200",
        value: 6,
      },
    ],
  },
  ColorProfileSliderOptions: {
    defaultValue: 2,
    min: 1,
    step: 1,
    max: 3,
    showLabelValueOnTop: false,
    marks: [
      {
        label: "Flat",
        value: 1,
      },
      {
        label: "GoPro",
        value: 2,
      },
      {
        label: "Vibrant",
        value: 3,
      },
    ],
  },
  ShutterSpeedSliderOptions: {
    defaultValue: 200,
    min: 60,
    step: 20,
    max: 1000,
    showLabelValueOnTop: false,
    marks: [],
    formatValue: (value) => {
      return `1/${value}`;
    },
  },
  WhiteBalanceSliderOptions: {
    defaultValue: 3500,
    min: 2300,
    step: 500,
    max: 6500,
    showLabelValueOnTop: false,
    marks: [],
    formatValue: (value) => {
      return `${value} K`;
    },
  },
  HyperSmoothSliderOptions: {
    defaultValue: 1,
    min: 0,
    step: 1,
    max: 2,
    showLabelValueOnTop: false,
    marks: [
      {
        label: "Boost",
        value: 0,
      },
      {
        label: "On",
        value: 1,
      },
      {
        label: "Off",
        value: 2,
      },
    ],
  },
};
export default filtersConfig;
