import React from "react";
import "./App.css";
import { FilterContextProvider } from "./components/FilterContext/FilterContext.js";
import SimContainer from "./components/SimContainer/SimContainer.js";
import Logo from "./components/assets/logo.webp";

function App() {
  return (
    <div className="App">
      <main>
        <header>
          <div className="logo-container">
            <img src={Logo} alt="Logo"/>
          </div>
          <div className="team-container">
            <h1>GoPro Hackathon 2023</h1>
            <p>Team The Simsons - Camera Simulator Project</p>
          </div>
        </header>
        <FilterContextProvider>
          <SimContainer />
        </FilterContextProvider>
      </main>
    </div>
  );
}

export default App;
